<template>
  <div>
    <div class="funMedicine zZjpagesNum pdfHeight">
      <div class="directory">
        <div class="funMedicinesecod_line">
          <Head />
        </div>
        <div class="title">
          <div class="title_top">
            <div class="title_top_text">
              <div class="title_top_l">
                DIRECTORY
              </div>
              <div class="title_top_c">目录</div>
            </div>
            <div class="title_top_r">
              <img
                src="https://image.giantgocloud.com/www/ImageMapping/image/20240927/56FE0227802341C59197987CFF1D9046.png"
                class="wh100" alt="">
            </div>
          </div>
          <div class="title_btn">
            <div class="title_btn_l"></div>
            <div class="title_btn_r"></div>
          </div>
        </div>
        <div class="directory-list">
          <div class="directory-list-item">
            <div class="directory-list-top">
              <div class="left_t">
                <div class="icon">
                  <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240927/7B2B0D4F270C4093BC96B29ACBAAA6EA.png" class="wh100" alt="">
                </div>
                <div class="text">指标等级</div>
              </div>
              <div class="page">03</div>
            </div>
            <div class="directory-list-btn"></div>
          </div>
          <div class="directory-list-item">
            <div class="directory-list-top">
              <div class="left_t">
                <div class="icon">
                  <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240927/75C6DF3FA39741AEA04CC173F25ED543.png" class="wh100" alt="">
                </div>
                <div class="text"> 数据概览</div>
              </div>
              <div class="page">04</div>
            </div>
            <div class="directory-list-btn"></div>
          </div>
          <div v-if="item.pageNum" v-for="(item, index) in directoryList" :key="index">
            <div class="directory-list-item">
              <div class="directory-list-top">
              <div class="left_t">
                <div class="icon">
                  <img :src="item.ThemePicUrl" class="wh100" alt="">
                </div>
                <div class="text">{{  item.SickTitle }}</div>
              </div>
              <div class="page">
                <template v-if="item.pageNum <= 9">0</template>{{ item.pageNum }}
              </div>
            </div>
            <div class="directory-list-btn"></div>
              <!-- <div class="text">
                {{ item.SickTitle }}
              </div>
              <div class="line"></div>
              <div class="text" style="padding-top: 2px;">
                <template v-if="item.pageNum <= 9">0</template>{{ item.pageNum }}
              </div> -->
            </div>
          </div>
        </div>
        <div style="position: absolute;left: 530px;bottom: 30px;;font-size: 10px;color: #000">
          1 of {{ alNum -1 }}
        </div>
      </div>
    </div>
    <!-- <div class="funMedicine zZjpagesNum">
      <div class="directory">
          <div class="funMedicinesecod_line"></div>
          <div class="title">
              <div style="font-size: 32px;color: #4E4E4E;">目录</div>
              <div style="font-size: 12px;color: #7B7B7B;">DIRECTORY</div>
          </div>
          <div class="directory-list">
            <div v-for="(item, index) in directoryList" :key="index">
              <div v-if="index>=12" class="directory-list-item" >
                  <div class="text">
                      {{ item.SickTitle }}
                  </div>
                  <div class="line"></div>
                  <div class="text" style="padding-top: 2px;">
                      0{{ index + 1 }}
                  </div>
              </div>
            </div>

          </div>
          <div style="position: absolute;left: 360px;bottom: 30px;;font-size: 12px;color: #1386B0">
              全方位健康分析（共{{alNum}}页）P3
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Head from './head.vue'
export default {
  data () {
    return {

    }
  },
  components: {
    Head
  },
  props: {
    directoryList: Array,
    numPageA: Number,
    alNum: Number
  },
  mounted () {
  }
}
</script>

<style lang='scss' scoped>
.funMedicinesecod_line {}

.funMedicine {
  width: 595px;
  position: relative;
  /* height: 845px; */
  box-sizing: border-box;
  overflow: hidden;
}

.directory {
  height: 100%;
  overflow: hidden;
  // position: relative;

  .title {
    position: relative;
    padding-top: 30px;
    padding-left: 39px;
    z-index: 999;

    .title_top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title_top_text {
        display: flex;
        align-items: center;

        .title_top_l {
          font-size: 22px;
        }

        .title_top_c {
          font-size: 12px;
          font-weight: 600;
          color: #33AEAA
        }
      }

      .title_top_r {
        width: 22px;
        height: 28.5px;
        margin-right: 46px;
      }
    }

    .title_btn {
      display: flex;

      .title_btn_l {
        width: 32px;
        height: 2px;
        background-color: #33AEAA;
      }

      .title_btn_r {
        margin-left: 4px;
        width: 472px;
        height: 2px;
        background-color: #33AEAA;
      }
    }
  }

  .directory-list {
    position: relative;
    padding: 0 45px;
    padding-left: 70px;
    padding-top: 10px;
    z-index: 9999;
    color: #4E4E4E;
    .directory-list-item {
      // display: flex;
      padding-top: 12px;
      width: 440px;
      .directory-list-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .left_t{
          display: flex;
          align-items: center;

          .icon{
            width: 22px;
            height: 18px;
          }
          .text,.page{
            font-size: 10px;
          }
        }
      }
      .directory-list-btn{
        width: 430px;
        margin: 0 auto;
        margin-top: 4px;
        border-bottom: 1px dashed #33AEAA;
      }
    }
  }
}
</style>
