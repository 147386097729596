<template>
    <div class="repair">
        <div class="repair3" v-for="item in textData">
            <div class="title">{{ item.name }}</div>
            <div class="text">
                {{ item.text }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    props: {
        textData: Array
    }
}
</script>

<style lang='scss' scoped>
.repair {
    margin-left: 30px;
    margin-top: 30px;
    padding: 0 30px;
    // background-color: #E7F3F7;
    padding-bottom: 20px;
}

.repair3 {

    padding-top: 14px;

    .title {
        font-size: 12px;
        color: #1386B0;
    }

    .text {
        color: #4E4E4E;
        font-size: 10px;
        padding-top: 2px;
    }
}
</style>