<template>
  <div class="directory">
    <div class="funMedicinesecod_line">
      <Head />
    </div>
    <div class="Level_title">指标等级</div>
    <div style="font-size: 12px;padding-left: 60px;margin-top: 16px;display: flex;align-items: center;">
      <div style="width: 20px;height: 20px;">
        <img class="wh100" src="https://image.giantgocloud.com/www/ImageMapping/image/20240927/F3A495B8C2F64A29AE2F5D777570EE59.png" alt="">
      </div>
      <div style="padding-left: 6px;">请关注B-以上主题</div>
    </div>
    <div class="Level_Analyzed">
      <div class="title_top_r">
        <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240927/4A442A5CAFE34F4EAE9B739CD123EA91.png"
          class="wh100" alt="">
      </div>
      已分析器官/系统等级
    </div>
    <div class="Level_ul">
      <template v-for="(item, index) in LevelDetails">
        <div v-if="item.Level" class="Level_li" :key="index">
          <div class="Level_li_left" :style="{ backgroundColor: item.themeColorCode }">{{ item.Level }}</div>
          <div class="Level_li_text">{{ item.Theme }}</div>
        </div>
      </template>
    </div>
    <div class="Level_Analyzed">
      <div class="title_top_r">
        <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240927/F6C1CA1FC64F42559B7C324517183EC6.png"
          class="wh100" alt="">
      </div>
      未分析器官/系统等级
    </div>
    <div class="Level_ul">
      <template v-for="(item, index) in LevelDetails">
        <div v-if="!item.Level" class="Level_li" :key="index">
          <div class="Level_li_left" >{{ item.Level }}</div>
          <div class="Level_li_text">{{ item.Theme }}</div>
        </div>
      </template>
    </div>
    <div style="position: absolute;left: 530px;bottom: 30px;;font-size: 10px;color: #000">
      2 of {{ alNum -1}}
    </div>
  </div>
</template>

<script>
import Head from './head.vue'
export default {
  props: {
    LevelDetails: Array,
    numPageA: Number,
    alNum: Number
  },
  components: {
    Head
  }
}
</script>

<style lang='scss' scoped>
.directory {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.Level_Analyzed {
  position: relative;
  width: 200px;
  height: 26px;
  line-height: 26px;
  background-color: #EBEBEC;
  font-size: 14px;
  margin-left: 49px;
  margin-top: 30px;
  border-radius: 6px;
  padding-left: 34px;
  .title_top_r {
    position: absolute;
    left: 6px;
    top: -6px;
    width: 28px;
    height: 28px;
    margin-right: 46px;
  }
}

.Level_ul {
  margin-top: 20px;
  margin-left: 49px;
  display: flex;
  flex-wrap: wrap;

  .Level_li {
    width: 180px;
    display: flex;
    align-items: center;
    margin-right: 150px;
    margin-bottom: 20px;

    .Level_li_left {
      background-color: #EBEBEC;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      font-size: 18px;
      color: #fff;
      opacity: 1;
    }

    .Level_li_text {
      margin-left: 24px;
      font-size: 12px;
    }
  }

  .Level_li:nth-of-type(2n) {
    margin-right: 0;
  }
}

.Level_title {
  margin-left: 49px;
  margin-top: 20px;
  width: 146px;
  height: 26px;
  background-color: #33AEAA;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 26px;
  letter-spacing: 1px;
  text-align: center;
  border-radius: 6px;
}

.funMedicinesecod_line {
  // width: 500px;
  // height: 1px;
  // background: #4E4E4E;
  // margin: 0 auto;
  // margin-top: 30px;
  // margin-bottom: 20px;
}
</style>
