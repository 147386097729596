<template>
    <div>
        <div class="repair">
            <!-- <Headers :headerData="headerData.head" /> -->
            <!-- <div class="funMedicinesecod_line"></div> -->

            <Head :TitleData="TitleData" :ThemeDescribe="ThemeDescribe" :finalAnalysisDataDetailTitleDtos="finalAnalysisDataDetailTitleDtos" />
            <div style="position: absolute;left: 360px;bottom: 30px;font-size: 12px;color: #1386B0">
                终极健康分析（共{{numPageA}}页）P{{ headerData.pagesNum[0] }}
            </div>
        </div>
        <div class="repair" v-if="TitleData.name == '修复'||TitleData.name == '免疫与炎症'">

            <!-- <div class="funMedicinesecod_line"></div> -->
            <Head :TitleData="TitleData" :finalAnalysisDataDetailTitleDtos="finalAnalysisDataDetailTitleDtos" v-if="TitleData.name == '修复'||TitleData.name == '免疫与炎症'" repairData="repairData"/>
            <div style="position: absolute;left: 360px;bottom: 30px;font-size: 12px;color: #1386B0">
                终极健康分析（共{{numPageA}}页）P{{ headerData.pagesNum[1] }}
            </div>
        </div>
    </div>
</template>
<script>
import Head from "./component/Head.vue"
// import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
import TextListBgc from "./component/TextListBgc.vue"
export default {
    data() {
        return {
            TitleData:{
                name:'',
                textData:[],
                ThemeDescribe:'',
            },
            textData: [

            ],
            ThemeDescribe:''
        }
    },
    watch:{
      headerData: {
      				handler (newName, oldName) {
      					console.log('headerData',this.headerData)
      				},
      				// immediate: true,
              deep: true
      			}
    },
    created() {
      setTimeout(()=>{
        if(!this.finalAnalysisDataDetailTitleDtos){
            return
        }
      console.log('this.finalAnalysisDataDetailTitleDtos11111',this.finalAnalysisDataDetailTitleDtos);
      console.log('this.finalAnalysisSickItem0101',this.finalAnalysisSickItem);
      try{
        this.TitleData=this.finalAnalysisSickItem;
        this.TitleData.textData=this.finalAnalysisDataDetailTitleDtos;
        this.TitleData.name=this.TitleData.SickTitle
        this.TitleData.ThemeDescribe=this.finalAnalysisDataDetailTitleDtos.ThemeDescribe
      }catch(e){
        //TODO handle the exception
      }
      console.log('this.this.TitleData',this.TitleData)

      },30)

    },
    components: {
        Head,
        TextListBgc,
        // Headers,
        Footer
    },
    props: {
        headerData: Object,
        finalAnalysisDataDetailTitleDtos:Object,
        index1:Number,
        finalAnalysisSickItem:Object,
        numPageA:Number
    },
    computed: {
        "totalNumber": function () {
            // let result = { count: 0, currentPage: 0 }
            // result.count = this.headerData.pageCount
            // //计算当前页码
            // let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "心血管")
            // result.currentPage = currentIndex + 1
            // return result
        }
    },
    mounted(){
        console.log(this.finalAnalysisDataDetailTitleDtos,'000000000')
    }
}
</script>

<style lang='scss' scoped>
  .funMedicinesecod_line {
    width: 500px;
    height: 1px;
    background: #4E4E4E;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 20px;
  }

.repair {
    position: relative;
    overflow: hidden;
}

.repair1 {
    font-size: 48px;
    color: #1386B0;
    padding-top: 51px;
    padding-left: 45px;
}

.repair2 {
    font-size: 12px;
    color: #4E4E4E;
    padding: 0 45px;
    padding-top: 43px;
}

.repair3 {
    padding-top: 29px;
    padding-left: 45px;

    .title {
        font-size: 14px;
        color: #1386B0;
    }

    .text {
        color: #4E4E4E;
        font-size: 10px;
        padding-top: 4px;
    }
}

.repair4 {
    width: 595px;
    height: 351px;
    margin-top: 59px;

    img {
        width: 100%;
        height: 100%;
    }
}

.page {
    position: absolute;
    left: 543px;
    bottom: -144px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;

    .page_img {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .icon-number {
        position: relative;
    }
}
</style>
