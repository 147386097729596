<template>
  <div>
    <div  class="repair zZjpagesNum">
     <div class="funMedicinesecod_line"></div>
     <div  style="display: flex;align-items: center;">
        <div  class="repair1">
          {{ finalAnalysisDataDetailTitleDtos.Theme }}
        </div>
        <div class="Level_T"  :style="{backgroundColor: finalAnalysisDataDetailTitleDtos.themeColorCode}">{{finalAnalysisDataDetailTitleDtos.themeLevel}}</div>
      </div>
      <div class="repair2">
        <div >
          {{finalAnalysisDataDetailTitleDtos.ThemeDescribe }}
        </div>
      </div>
      <div  style="position: absolute;left: 360px;bottom: 30px;;font-size: 12px;color: #000">
        （共{{alNum}}页）P
       </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  props: {
    finalAnalysisDataDetailTitleDtos: Object,
    alNum: Number
  },
  created () {
    console.log('0000000000000000000001', this.finalAnalysisDataDetailTitleDtos)
    // let dataList = this.TitleData.textList.split(';')
    // console.log(dataList,'dataList1111')
    // this.datatextList = dataList
    // console.log(dataList, 'dataList')
  }

}
</script>

<style lang='scss' scoped>
  .funMedicinesecod_line {
    width: 500px;
    height: 1px;
    background: #4E4E4E;
    margin: 0 auto;
    margin-top: 61px;
    margin-bottom: 32px;
  }
.Level_T{
        margin-left: 10px;
        position: relative;
        top: 7px;
        background:'#b0b1b7';
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 30px;
        border-radius: 4px 4px 4px 4px;
        font-size: 24px;
        color:#fff;
        opacity: 1;
      }
  .repair {
    position: relative;
    height: 842px;
    overflow: hidden;

    .repair1 {
      letter-spacing: 0px;
      font-size: 32px;
      color: #1386B0;
      padding-top: 10px;
      padding-left: 45px;
    }

    .repair2 {
      font-size: 12px;
      color: #4E4E4E;
      padding: 0 45px;
      padding-top: 10px;
    }

    .repair3 {
      position: absolute;
      left: 410px;
      top: 20px;

      .icons {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .repair4 {
      font-size: 12px;
      color: #4E4E4E;
      padding: 0 45px;
      padding-top: 10px;

      .repair4_title {
        color: #1386B0;
        font-size: 12px;
      }

      .repair4_content {
        color: #4E4E4E;
        font-size: 10px;
        margin: 5px 0;
      }
    }
  }
</style>
