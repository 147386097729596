<template>
    <div class="contentPage">
        <!-- <div class="text"> -->
        终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        <!-- </div> -->
    </div>
</template>
<script>
export default {
    props: {
        totalNumber: Object
    },
}
</script>
<style lang='scss' scoped>
.contentPage {
    position: absolute;
    bottom: -30px;
    left: 400px;
    font-size: 12px;
    color: #1386B0
}
</style>