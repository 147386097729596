<template>
  <div class="content">
    <div>
      <div v-for="index1 of Math.ceil(finalAnalysisSickItem.SickTitleDetail.length/10)" class="identifying zZjpagesNum pdfHeight" v-if="finalAnalysisSickItem.SickTitleDetail.length&&finalAnalysisSickItem.SickTitleDetail[0].CheckItem">
        <div class="funMedicinesecod_line"></div>
        <div class="funMedicine5Cloumns"></div>
        <div class="funMedicine5Text">
          <div class="funMedicine5TextTop">YOUR PROJECT METRICS</div>
          <div class="funMedicine5TextBottom">您的项目指标</div>
        </div>
        <div class="table-wrapper">
          <table>
            <tr style="color: #fff;font-size: 14px;">
              <th id="someElementTitle">{{ finalAnalysisSickItem.SickTitle }}</th>
              <th>结果</th>
              <th>状态</th>
              <th>范围</th>
              <th>等级</th>
            </tr>
           <tr v-for="(item5, index) in finalAnalysisSickItem.SickTitleDetail" :key="index"
              v-if="item5.CheckResultValue && (index1-1)*10<=Number(index)&&Number(index)<index1*10" >
              <td class="IndicatorListCheckItem">{{ item5.CheckItem }}</td>
              <td class="IndicatorListCheckasult">{{ item5.CheckResultValue }}{{ item5.CheckResultUnit }}</td>
              <td class="processdata" style="position: relative;">
                <div class="process">
                  <div style="display: flex;" v-for="(color, index) in item5.ColorLengths" :key="index" >
                    <div v-if="index!=0" style='width: 2px;'></div>
                    <div class="process1"  :style="{
                      width: `${(150 / item5.ColorLengths.length).toFixed(1) + 'px'}`,
                      background: `${color.ColorNum}`,
                    }">
                    </div>
                  </div>
                </div>
                <div class="Yuan" :style="{
                  border: `2px solid ${item5.RoundColorNum}`,
                  transform: `translate(${-80 + (150 * Number(item5.ColorValuePercent)) / 100
                    }px,-6px)`,
                }">
                </div>

              </td>
              <td v-if="item5.maxRefence>=9999" class="IndicatorListCheckstatus">
                >={{ item5.minRefence }}
              </td>
              <td v-else class="IndicatorListCheckstatus">
                {{ item5.minRefence }}-{{item5.maxRefence}}
              </td>
              <td class="IndicatorListCheckgrade">{{ item5.Level }}</td>
            </tr>
          </table>

        </div>
        <!-- {{finalAnalysisSickItem.SickTitleDetail[(index1-1)*10]}} -->
        <!-- {{finalAnalysisSickItem.SickTitleDetail}} -->
       <div  style="position: absolute;left: 360px;bottom: 30px;;font-size: 12px;color: #1386B0">
       （共{{alNum}}页）P{{finalAnalysisSickItem.SickTitleDetail[(index1-1)*10].pageNum}}
        </div>
      </div>
     <div v-for="(item,index) in finalAnalysisSickItem.newFinalAnalysisSickItem" v-if="finalAnalysisSickItem.newFinalAnalysisSickItem.length&&finalAnalysisSickItem.newFinalAnalysisSickItem[0].SickTitleDetail.length&&finalAnalysisSickItem.newFinalAnalysisSickItem[0].SickTitleDetail[0].CheckItem"  class="funMedicine6 zZjpagesNum pdfHeight">
        <div class="funMedicinesecod_line" style="position: absolute;top: 0;"></div>
       <div v-if="finalAnalysisSickItem.SickTitle" class="funMedicine6_box" :class="'funMedicine6_box'+typeIndex"
          v-for="(item2, index2) in item.SickTitleDetail" :key="index2">
          <div>
            <div v-if="item2.CheckItem" class="funMedicine6HeadTop">
             <div class="funMedicine6Head" v-if="item">
                <span>您的{{ item2.CheckItem }}指标为：</span>
                <span>
                   {{item2.CheckResultValue}}
                  ({{item2.CheckResultUnit}})&nbsp;&nbsp;
                    <span v-if="item2.maxRefence>=9999"> [ >={{ item2.minRefence }} ]</span>
                    <span v-else-if="(item2.maxRefence||item2.minRefence===0)&&item2.maxRefence!==0">[{{ item2.minRefence }}-{{item2.maxRefence}}]</span>
                </span>
              </div>
              <div class="funMedicine6Cloumns"></div>

             <div class="process processA" v-if="item2" style="margin-top: 50px;">
               <div style="display: flex;" v-for="(color, index) in item2.ColorLengths" :key="index" >
                 <div v-if="index!=0" style='width: 2px;'></div>
                <div class="process1"
                  :class="index == item2.ColorLengths.length - 1 ? 'process1_A' : ''" :style="{
                    width: `${(180 / item2.ColorLengths.length).toFixed(1) + 'px'}`,
                    background: `${color.ColorNum}`,
                  }">
                  <span>{{ color.ColorName }}</span>
                </div>
                </div>
                <div class="Yuan" :style="{
                  border: `2px solid ${item2.RoundColorNum}`, top: `-4px`, position: `absolute`, left: `${((item2.ColorValuePercent / 100) * 150).toFixed(1) + 'px'}`
                }">
                  <div style="position: relative;width: 100%;height: 100%;">
                    <div class="funMedicine6Num" :style="{
                      position: `relative`, top: `-50px`, left: `0`
                    }">
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="funMedicine6Text">
                  {{ item2.CheckItem }}：
                </div>
                <div class="problem">
                  <div class="problemText">
                    {{ item2.CheckItemDetail }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="position: absolute;left: 360px;bottom: 30px;font-size: 12px;color: #000">
          （共{{alNum}}页）P{{finalAnalysisSickItem.newFinalAnalysisSickItem[index].pageNum}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Headers from './component/headers.vue'
import Footer from './component/footer.vue'
import Cardiovascular from '../componetPdf/cardiovascular.vue'
export default {
  components: {
    Headers,
    Footer,
    Cardiovascular
  },
  data () {
    return {
      finalAnalysisDataDetailTitleDtosNew: [],
      finalAnalysisSickItemNew: [],
      newlistDataA: [{
        data: []
      }],
      valueI: 0,
      numPage: 0,
      num: 0,
      // 最外层
      wNum: 0,
      A: [],
      bol: true,
      headerDataA: {

      },
      numPageA: 0,
      NUmaA: 0
    }
  },
  props: {
    IndicatorList: Array,
    headerData: Object,
    titles: String,
    newlistData: Array,
    finalAnalysisSickItem: Object,
    finalAnalysisDataDetailTitleDtos: Array,
    typeIndex: Number,
    listData: Object,
    alNum: Number
  },
  methods: {
    setData () {
      const that = this
      that.wNum = 0
      that.num = 0
      if (that.finalAnalysisSickItem.SickTitleDetail.length) {
        const SickTitleDetail = {
          SickTitleDetail: []
        }
        that.finalAnalysisSickItem.newFinalAnalysisSickItem.push(SickTitleDetail)
        that.newList()
      }
      console.log('that.listData11', that.listData)
      setTimeout(() => {
        // 获取页数
        let pageNum
        if (that.listData.LevelDetails.length >= 12) {
          pageNum = 5
        } else {
          pageNum = 4
        }
        for (let i = 0; i < that.listData.finalAnalysisDataDetailTitleDtos.length; i++) {
          that.listData.finalAnalysisDataDetailTitleDtos[i].pageNum = []
          console.log('that.finalAnalysisDataDetailTitleDtos[i].finalAnalysisDataDetailDtos', that.listData.finalAnalysisDataDetailTitleDtos[i].finalAnalysisDataDetailDtos)
          console.log('Math.ceil(that.finalAnalysisDataDetailTitleDtos[i].finalAnalysisDataDetailDtos.length/6)', Math.ceil(that.listData.finalAnalysisDataDetailTitleDtos[i].finalAnalysisDataDetailDtos.length / 6))
          for (let q = 0; q < Math.ceil(that.listData.finalAnalysisDataDetailTitleDtos[i].finalAnalysisDataDetailDtos.length / 6); q++) {
            that.listData.finalAnalysisDataDetailTitleDtos[i].pageNum.push(pageNum)
            pageNum++
          }

          for (let q = 0; q < that.listData.finalAnalysisSickItem[i].SickTitleDetail.length; q = q + 10) {
            // console.log('that.listData.finalAnalysisSickItem[i].SickTitleDetail',that.listData.finalAnalysisSickItem[i].SickTitleDetail)
            if (that.listData.finalAnalysisSickItem[i].SickTitleDetail[0].CheckItem) {
              that.listData.finalAnalysisSickItem[i].SickTitleDetail[q].pageNum = pageNum
              pageNum++
            }
          }

          for (let q = 0; q < that.listData.finalAnalysisSickItem[i].newFinalAnalysisSickItem.length; q++) {
            console.log('that.listData.finalAnalysisSickItem[i].newFinalAnalysisSickItem[q].SickTitleDetail', that.listData.finalAnalysisSickItem[i].newFinalAnalysisSickItem[q].SickTitleDetail)
            if (that.listData.finalAnalysisSickItem[i].newFinalAnalysisSickItem[q].SickTitleDetail.length > 1 || that.listData.finalAnalysisSickItem[i].newFinalAnalysisSickItem[q].SickTitleDetail[0].CheckItem) {
              that.listData.finalAnalysisSickItem[i].newFinalAnalysisSickItem[q].pageNum = pageNum
              pageNum++
            }
          }
        }
        const zZjpagesNum = document.getElementsByClassName('zZjpagesNum')
        that.listData.finalAnalysisSickItem[that.typeIndex].newFinalAnalysisSickItem = that.finalAnalysisSickItem.newFinalAnalysisSickItem
        // localStorage.setItem('alNum', zZjpagesNum.length);
        console.log('pageNum', pageNum)
        localStorage.setItem('listDataPdfZ', JSON.stringify(that.listData))
        location.reload()
      }, 500)
    },
    newList () {
      const that = this
      const SickTitleDetail = {
        SickTitleDetail: []
      }
      if (that.finalAnalysisSickItem.SickTitleDetail.length > that.num) {
        that.finalAnalysisSickItem.newFinalAnalysisSickItem[that.wNum].SickTitleDetail.push(that.finalAnalysisSickItem.SickTitleDetail[that.num])
        that.$forceUpdate()
        setTimeout(() => {
          const getDivHeightBox = document.getElementsByClassName('funMedicine6_box' + that.typeIndex)
          if (getDivHeightBox.length >= 1 && getDivHeightBox[getDivHeightBox.length - 1].offsetLeft > 500) {
            that.finalAnalysisSickItem.newFinalAnalysisSickItem[that.wNum].SickTitleDetail = that.finalAnalysisSickItem.newFinalAnalysisSickItem[that.wNum].SickTitleDetail.slice(0, -1)
            console.log('that.finalAnalysisSickItem.newFinalAnalysisSickItem[that.wNum].SickTitleDetail', that.finalAnalysisSickItem.newFinalAnalysisSickItem[that.wNum].SickTitleDetail)
            that.finalAnalysisSickItem.newFinalAnalysisSickItem.push(SickTitleDetail)
            that.wNum++
            that.newList()
          } else {
            that.num++
            that.newList()
          }
        }, 1)
      } else {

      }
    }

  },
  mounted () {
    const that = this
    console.log('finalAnalysisSickItem1', this.finalAnalysisSickItem)
    if (localStorage.getItem('listDataPdfZ')) {
      // this.alNum=localStorage.getItem('alNum');
    } else {
      this.setData()
    }
  },
  computed: {
    totalNumber: function () {
      // let result = {
      //   count: 0,
      //   currentPage: 0,
      //   pageArray: []
      // }
      // result.count = this.headerData.pageCount
      // //计算当前页码
      // let currentIndex = this.headerData.resultArray.findIndex(v => v.title == this.titles)
      // let dimensionalArray = this.headerData.resultArray.map(v => v.title)
      // result.currentPage = currentIndex + 1
      // result.pageArray = dimensionalArray
      // return result
    }

  }
}
</script>

<style lang='scss' scoped>
  .process1_A {
    height: 4px;
    background: #e29a30;
    opacity: 1;
    border-radius: 0px 50px 50px 0px !important;
  }

  .funMedicinesecod_line {
    width: 500px;
    height: 1px;
    background: #4E4E4E;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .funMedicineTitle {
    position: relative;
  }

  .identifying {
    position: relative;
    overflow: hidden;

    .funMedicine5Cloumns {
      display: inline-block;
      width: 4px;
      height: 54px;
      margin-top: 64px;
      margin-left: 45px;
      background: linear-gradient(117deg,
          #1386b0 0%,
          rgba(19, 134, 176, 0) 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }

    .funMedicine5Text {
      margin-bottom: 30px;
      margin-left: 8px;
      display: inline-block;

      .funMedicine5TextTop {
        // width: 306px;
        height: 26px;
        font-size: 24px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1386b0;
        line-height: 26px;
        -webkit-background-clip: text;
      }

      .funMedicine5TextBottom {
        margin-top: 8px;
        width: 162px;
        height: 26px;
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #4e4e4e;
        line-height: 26px;
        letter-spacing: 1px;
        -webkit-background-clip: text;
      }
    }

    .table-wrapper {
      overflow: hidden;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      width: 509px;
      min-height: 300px;
      margin: auto;
      overflow-x: scroll;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #4e4e4e;
      line-height: 26px;
      -webkit-background-clip: text;
    }

    .processdata {
      width: 150px;

      .process {
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 150px;

        // margin: 30px 0;
        span {
          display: inline-block;
          transform: translateY(10px);
          margin-top: 5px;
        }

        .process1 {
          // width: 100px;
          height: 5px;
          background: #5ec692;
          opacity: 1;
        }

        .process1:first-child {
          // width: 100px;
          height: 5px;
          background: #d51931;
          opacity: 1;
          border-radius: 50px;
        }

        .process1:last-child {
          // width: 100px;
          height: 5px;
          background: #e29a30;
          opacity: 1;
          border-radius: 50px ;
        }

      }

      .Yuan {
        position: absolute;
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: white;
      }
    }

    table {
      text-align: center;
      width: 509px;
      border-collapse: collapse;

      tr {
        width: 509px;
        height: 47px;
      }

      tr:nth-child(even) {
        background: rgb(232, 246, 250);
        opacity: 1;
      }

      tr:nth-child(1) {
        background: linear-gradient(90deg, #19a1d2 0%, #25d1dc 100%);
        opacity: 1;
      }
    }

    .page {
      position: absolute;
      width: 40px;
      height: 40px;
      background: #1386b0;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      right: 0px;
      bottom: -10px;
      background: url("../../../assets/analysis/be9299d8ce7aecbcc66619a0dd3f403.png") no-repeat;
      background-size: contain;
      text-align: center;
      font-size: 13px;
      padding-left: 2px;
      box-sizing: border-box;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #ffffff;
      line-height: 33px;
    }
  }

  .line {
    width: 500px;
    height: 1px;
    background: #4E4E4E;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin: 61px auto;
  }

  .content {
    .IndicatorListCheckItem {
      width: 90px;
      font-size: 10px;
    }

    .IndicatorListCheckasult {
      font-size: 10px;
    }

    .IndicatorListCheckstatus {
      font-size: 10px;
    }

    .IndicatorListCheckgrade {
      font-size: 10px;
    }

    .funMedicine5 {
      position: relative;
      overflow: hidden;

      .funMedicine5Cloumns {
        display: inline-block;
        width: 4px;
        height: 54px;
        margin-top: 64px;
        margin-left: 45px;
        background: linear-gradient(117deg,
            #1386b0 0%,
            rgba(19, 134, 176, 0) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }

      .funMedicine5Text {
        margin-bottom: 30px;
        margin-left: 8px;
        display: inline-block;

        .funMedicine5TextTop {
          // width: 306px;
          height: 26px;
          font-size: 24px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1386b0;
          line-height: 26px;
          -webkit-background-clip: text;
        }

        .funMedicine5TextBottom {
          margin-top: 8px;
          width: 162px;
          height: 26px;
          font-size: 24px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #4e4e4e;
          line-height: 26px;
          letter-spacing: 1px;
          -webkit-background-clip: text;
        }
      }

      .table-wrapper {
        overflow: hidden;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 509px;
        min-height: 300px;
        margin: auto;
        overflow-x: scroll;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #4e4e4e;
        line-height: 26px;
        -webkit-background-clip: text;
      }

      .processdata {
        width: 150px;

        .process {
          position: relative;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 150px;

          span {
            display: inline-block;
            transform: translateY(10px);
            margin-top: 5px;
          }

          .process1 {
            // width: 100px;
            height: 5px;
            background: #5ec692;
            opacity: 1;
            border-radius: 50px;
          }

          .process1:nth-of-type(1) {
            height: 4px;
            background: #d51931;
            opacity: 1;
            border-radius: 50px ;
          }

          .process1:nth-last-of-type(1) {
            height: 4px;
            background: #e29a30;
            opacity: 1;
            border-radius:  50px  !important;
          }

          .process1_A {
            height: 4px;
            background: #e29a30;
            opacity: 1;
            border-radius:  50px  !important;
          }
        }

        .Yuan {
          position: absolute;
          display: inline-block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: white;
        }
      }

      table {
        text-align: center;
        width: 509px;
        border-collapse: collapse;

        tr {
          width: 509px;
          height: 47px;
        }

        tr:nth-child(even) {
          background: rgb(232, 246, 250);
          opacity: 1;
        }

        tr:nth-child(1) {
          background: linear-gradient(90deg, #19a1d2 0%, #25d1dc 100%);
          opacity: 1;
        }
      }

      .page {
        position: absolute;
        width: 40px;
        height: 40px;
        background: #1386b0;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        right: 0px;
        bottom: -10px;
        background: url("../../../assets/analysis/be9299d8ce7aecbcc66619a0dd3f403.png") no-repeat;
        background-size: contain;
        text-align: center;
        font-size: 13px;
        padding-left: 2px;
        box-sizing: border-box;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #ffffff;
        line-height: 33px;
      }
    }

    .funMedicine6 {
      position: relative;
      /* height: 845px; */
      // overflow: hidden;
      // background-repeat: no-repeat;
      // background-size: 100% 50%;
      // background-image: url("../../../assets/analysis/74bb1640c32d38c6ca82b00dadac7be.png"),
      //     url("../../../assets/analysis/370f08fe4ae8c4b519af7ae7e27b32c.png");
      // background-position: top, bottom;
      // display: flex;
      // padding: 30px;
      column-count: 2;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 55px 60px 40px 60px;

      .funMedicine6_box {
        width: 229px;
        // margin: 20px 0;
      }

      /* 设置两张图片的位置 */
      .funMedicine6HeadTop {
        margin-top: 10px;
        display: inline-block;
      }
      #processdata{
      }
      .funMedicine6Head {
        margin-top: 58px;
        // margin-left: 44px;
        // width: 367px;
        height: 26px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #4e4e4e;
        line-height: 26px;
        // letter-spacing: 1px;

        span {
          color: #1386b0;
        }
      }

      .funMedicine6Head:nth-of-type(1) {
        margin-top: 0
      }

      .funMedicine6Footer {
        position: absolute;
        width: 40px;
        height: 40px;
        background: #1386b0;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        right: 0px;
        bottom: -10px;
        background: url("../../../assets/analysis/be9299d8ce7aecbcc66619a0dd3f403.png") no-repeat;
        background-size: contain;
        text-align: center;
        font-size: 13px;
        padding-left: 2px;
        box-sizing: border-box;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #ffffff;
        line-height: 33px;
      }

      .funMedicine6Cloumns {
        // margin-top: 12px;
        // margin-left: 44px;
        width: 122px;
        height: 4px;
        // background: linear-gradient(117deg, #1386B0 0%, rgba(19, 134, 176, 0) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // margin: 10px 0;
      }

      .funMedicine6Num {
        width: 57px;
        height: 20px;
        font-size: 12px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #4e4e4e;
        line-height: 24px;
        margin-top: 20px;
        -webkit-background-clip: text;
      }

      .process {
        position: relative;
        display: flex;
        // justify-content: center;
        text-align: center;
        margin-top: 30px;

        span {
          display: inline-block;
          transform: translateY(10px);
          margin-top: 5px;
        }

        .process1 {
          // width: 100px;
          height: 4px;
          background: #5ec692;
          opacity: 1;
        }

        .process1:first-child {
          // width: 100px;
          height: 4px;
          background: #d51931;
          opacity: 1;
          border-radius: 50px 0px 0px 50px;
        }

        .process1:last-child {
          // width: 100px;
          height: 4px;
          background: #e29a30;
          opacity: 1;
        }
      }

      .Yuan {
        position: absolute;
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: white;
      }

      .processText {
        width: 528px;
        height: 26px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #4e4e4e;
        line-height: 26px;
        margin-top: 33px;
        margin-left: 44px;

        span {
          color: #1386b0;
        }
      }

      .funMedicine6Cloumn {
        display: inline-block;
        width: 4px;
        height: 30px;
        margin-right: 8px;
        margin-left: 45px;
        margin-top: 51px;
        vertical-align: middle;
        background: linear-gradient(117deg,
            #1386b0 0%,
            rgba(19, 134, 176, 0) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }

      .funMedicine6Text {
        margin-top: 25px;
        width: 229px;
        // height: 30px;
        font-size: 13px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #1386b0;
        // line-height: 30px;
        letter-spacing: 1px;
        // -webkit-background-clip: text;
        // vertical-align: middle;
      }

      .problem {
        width: 229px;
        // margin-left: 45px;
        font-size: 10px;
        // margin-top: 8px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        // line-height: 14px;

        .problemText {
          color: #4e4e4e;

          span {
            color: #1386b0;
            margin-right: 3px;
          }
        }
      }

      .problem:nth-child(1) {
        // margin-top: 24px;
      }

      .footers {
        margin-top: 12px;
        margin-left: 45px;
        color: #1386b0;
      }
    }
  }
  .processA{
    display: inline-flex !important;
  }
</style>
