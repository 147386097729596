<template>
  <div class="testReport_head">
    <div class="testReport_head_content">
      <div class="testReport_head_content_info" :style="'border-bottom:1px solid ' + bgColor">
        <div class="testReport_head_content_info_icon">
          <div class="testReport_head_content_info_icon_div" :style="'background:' + bgColor"></div>
          <div class="testReport_head_content_info_icon_div" :style="'background:' + bgColor"></div>
          <div class="testReport_head_content_info_icon_div" :style="'background:' + bgColor"></div>
        </div>
        <div class="testReport_head_content_info_text" v-if="userInfo">
          <div class="testReport_head_content_info_text_left">姓名</div>
          <div class="testReport_head_content_info_text_right">{{ userInfo.userName }}</div>
          <div class="testReport_head_content_info_text_S" :style="'background:' + bgColor"></div>
          <div class="testReport_head_content_info_text_left">性别</div>
          <div class="testReport_head_content_info_text_right">{{ userInfo.sex }}</div>
          <div class="testReport_head_content_info_text_S" :style="'background:' + bgColor"></div>
          <div class="testReport_head_content_info_text_left">出生日期</div>
          <div class="testReport_head_content_info_text_right">{{ userInfo.userBirthDay }}</div>
          <div class="testReport_head_content_info_text_S" :style="'background:' + bgColor"></div>
          <div class="testReport_head_content_info_text_left">年龄</div>
          <div class="testReport_head_content_info_text_right" style="margin-right: 0;">{{ userInfo.userAge }}</div>
        </div>
      </div>
      <div class="testReport_head_content_right" :style="'background-color:' + bgColorRight + ';'">
        <!--    <div class="testReport_head_content_right_text1">
          脂肪肝
        </div> -->
        <div class="testReport_head_content_right_text2">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String, // 类型
      required: false, // 是否必填
      default: '#33AEAA' // 默认值
    },
    bgColorRight: {
      type: String, // 类型
      required: false, // 是否必填
      default: '#33AEAA' // 默认值
    },

    text: {
      type: String, // 类型
      required: false, // 是否必填
      default: '健康数据' // 默认值
    } 
  },
   mounted() {
    let res = JSON.parse(sessionStorage.getItem('reportUserInfo'));
    this.userInfo = res;
    // console.log(res,565322)
  },
  data() {
    return {
      userInfo:''
    }
  }
}
</script>

<style lang='scss' scoped>
div {
  box-sizing: border-box;
}

.testReport_head {
  width: 595px;
  padding: 0 60px 0 54px;

  .testReport_head_content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .testReport_head_content_info {
      display: flex;
      align-items: center;
      padding-bottom: 13px;

      .testReport_head_content_info_icon {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 8px;
        position: relative;
        top: 0.5px;
        padding-right: 10px;
        .testReport_head_content_info_icon_div {
          margin-right:1%;
          background: #33AEAA;
          width: 9px;
          height: 1px;
        }
      }

      .testReport_head_content_info_text {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        color: #474F57;

        .testReport_head_content_info_text_left {
          margin-right: 10px;
          font-size: 600;
        }

        .testReport_head_content_info_text_right {
          margin-right: 8px;
          font-size: 200;
        }

        .testReport_head_content_info_text_S {
          margin-right: 10px;
          height: 8px;
          width: 1px;
          background: #33AEAA;
        }
      }
    }

    .testReport_head_content_right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;
      width: 86px;
      height: 63px;
      background: #33AEAA;
      border-radius: 0 0 15px 15px;

      .testReport_head_content_right_text1 {
        margin-bottom: 5px;
        font-size: 23px;
      }

      .testReport_head_content_right_text2 {
        font-size: 15px;
      }
    }
  }
}
</style>
